import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { generateRoutes, formatRoutes } from '@/utils/routeUtils'

Vue.use(VueRouter)

// 基础路由
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
    meta: { hidden: true }
  },
  {
    path: '/',
    redirect: '/login',
    meta: { hidden: true }
  }
]

// 主布局路由
export const mainRoute = {
  path: '/main',
  component: () => import('@/layouts/MainLayout.vue'),
  children: []
}

const router = new VueRouter({
  routes: constantRoutes
})

// 将 hasAddedRoutes 存储在 window 对象上，使其全局可访问
window.__hasAddedRoutes = false

router.beforeEach(async (to, from, next) => {
  // 如果是访问登录页
  if (to.path === '/login') {
    // 检查是否有用户信息和 cookie
    if (store.state.userInfo && document.cookie.includes('JSESSIONID')) {
      next('/main/dashboard')
    } else {
      next()
    }
    return
  }

  try {
    // 如果没有用户信息但有 cookie，尝试恢复会话
    if (!store.state.userInfo && document.cookie.includes('JSESSIONID')) {
      const userInfo = await store.dispatch('getUserInfo')
      if (!userInfo) {
        next('/login')
        return
      }
    } else if (!store.state.userInfo) {
      // 没有用户信息也没有 cookie，跳转登录
      next('/login')
      return
    }

    // 如果还没有添加动态路由
    if (!window.__hasAddedRoutes) {
      // 生成动态路由
      const accessRoutes = generateRoutes(store.state.menus)
      mainRoute.children = formatRoutes(accessRoutes)
      router.addRoute(mainRoute)
      window.__hasAddedRoutes = true

      // 如果是根路径，重定向到首页
      if (to.path === '/') {
        next('/main/dashboard')
      } else {
        // 重新进入当前路由
        next({ ...to, replace: true })
      }
      return
    }

    // 如果路由已添加，直接放行
    next()
  } catch (error) {
    console.error('路由守卫错误:', error)
    next('/login')
  }
})

export default router
