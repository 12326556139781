// 简单的错误日志记录实现
export function logError(error) {
    // 开发环境直接打印
    if (process.env.NODE_ENV === 'development') {
        console.error('Error:', error)
        return
    }

    // 获取已存储的错误日志
    const errorLogs = JSON.parse(localStorage.getItem('errorLogs') || '[]')

    // 限制日志数量，只保留最新的 100 条
    if (errorLogs.length >= 100) {
        errorLogs.shift()
    }

    // 添加新的错误日志
    errorLogs.push({
        ...error,
        timestamp: new Date().getTime(),
        url: window.location.href
    })

    // 保存回 localStorage
    localStorage.setItem('errorLogs', JSON.stringify(errorLogs))
} 