/**
 * 将后端返回的菜单数据转换为路由配置
 * @param {Array} menus 后端返回的菜单数据
 * @param {String} parentPath 父级路径
 * @returns {Array} 路由配置数组
 */
export function generateRoutes(menus, parentPath = '') {
  const routes = []
  
  menus.forEach(menu => {
    // 构建完整的路径
    const fullPath = parentPath ? `${parentPath}/${menu.path}` : menu.path
    
    const route = {
      path: menu.path,
      name: menu.menuCode,
      meta: {
        title: menu.menuName,
        icon: menu.icon,
        type: menu.type,
        status: menu.status,
        fullPath // 保存完整路径
      }
    }

    // 处理组件
    if (menu.component === 'Layout') {
      route.component = () => import('@/layouts/EmptyLayout.vue')
    } else if (menu.component.includes('/')) {
      // 已经是相对路径的情况
      route.component = () => import(`@/views/${menu.component}.vue`)
    } else {
      // 处理单个组件名的情况，添加父级路径
      const componentPath = parentPath 
        ? `${parentPath.toLowerCase()}/${menu.component}` 
        : menu.component
      route.component = () => import(`@/views/${componentPath}.vue`)
    }

    // 处理子路由
    if (menu.children && menu.children.length > 0) {
      route.children = generateRoutes(menu.children, fullPath)
      // 设置重定向到第一个子路由
      const firstChild = menu.children[0]
      route.redirect = `/main/${fullPath}/${firstChild.path}`
    }

    routes.push(route)
  })

  return routes
}

/**
 * 格式化路由，添加父级路径
 * @param {Array} routes 路由配置
 * @param {String} parentPath 父级路径
 */
export function formatRoutes(routes, parentPath = '') {
  routes.forEach(route => {
    route.meta = route.meta || {}
    route.meta.fullPath = `${parentPath}/${route.path}`.replace(/\/+/g, '/')
    
    if (route.children) {
      formatRoutes(route.children, route.meta.fullPath)
    }
  })
  return routes
} 