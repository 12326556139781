import Vue from 'vue'
import Vuex from 'vuex'
import {login, register, getUserMenuTree} from '@/utils/auth'
import request from "@/utils/request";
import VueRouter from 'vue-router'
import router from '@/router'
import { constantRoutes } from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')) || null,
      menuTree: JSON.parse(sessionStorage.getItem('menuTree')) || [],
      permissions: new Set(JSON.parse(sessionStorage.getItem('permissions')) || []),
      menus: JSON.parse(sessionStorage.getItem('menus')) || []
  },
  mutations: {
      SET_USER_INFO(state, userInfo) {
          state.userInfo = userInfo
          sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
      },
      SET_MENU_TREE(state, menuTree) {
          if (!Array.isArray(menuTree)) {
              console.error('菜单树数据格式错误:', menuTree)
              return
          }
          state.menuTree = menuTree
          sessionStorage.setItem('menuTree', JSON.stringify(menuTree))
      },
      SET_PERMISSIONS(state, menuTree) {
          if (!Array.isArray(menuTree)) {
              console.error('权限数据格式错误:', menuTree)
              return
          }
          const permissions = new Set()
          const extractPermissions = (menus) => {
              menus.forEach(menu => {
                  if (menu.type === 3) {
                      permissions.add(menu.menuCode)
                  }
                  if (menu.children) {
                      extractPermissions(menu.children)
                  }
              })
          }
          try {
              extractPermissions(menuTree)
              state.permissions = permissions
              sessionStorage.setItem('permissions', JSON.stringify(Array.from(permissions)))
          } catch (error) {
              console.error('提取权限时出错:', error)
          }
      },
      SET_MENUS(state, menus) {
          state.menus = menus
          sessionStorage.setItem('menus', JSON.stringify(menus))
      },
      CLEAR_USER_STATE(state) {
          state.userInfo = null
          state.menuTree = []
          state.permissions.clear()
          state.menus = []
          sessionStorage.removeItem('userInfo')
          sessionStorage.removeItem('menuTree')
          sessionStorage.removeItem('permissions')
          sessionStorage.removeItem('menus')
      }
  },
  actions: {
      login({ commit }, userInfo) {
          return new Promise((resolve, reject) => {
              login(userInfo).then(response => {
                  if (response.code !== 200) {
                      reject(new Error(response.msg || '登录失败'))
                      return
                  }
                  
                  const { data } = response
                  commit('SET_USER_INFO', data)
                  
                  getUserMenuTree().then(menuResponse => {
                      if (menuResponse.code !== 200) {
                          reject(new Error(menuResponse.msg || '获取菜单失败'))
                          return
                      }
                      
                      commit('SET_MENU_TREE', menuResponse.data)
                      commit('SET_PERMISSIONS', menuResponse.data)
                      commit('SET_MENUS', menuResponse.data)
                      resolve(menuResponse.data)
                  }).catch(error => {
                      console.error('获取菜单异常:', error)
                      reject(new Error(error.message || '获取菜单失败'))
                  })
              }).catch(error => {
                  console.error('登录异常:', error)
                  reject(new Error(error.message || '登录失败'))
              })
          })
      },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            // 调用后端登出接口
            request({
                url: '/auth/logout',
                method: 'post'
            }).then(() => {
                // 清除前端状态
                commit('CLEAR_USER_STATE')
                // 重置路由
                router.matcher = new VueRouter({
                    routes: constantRoutes
                }).matcher
                // 重置路由添加标志
                if (window.__hasAddedRoutes) {
                    window.__hasAddedRoutes = false
                }
                resolve()
            }).catch(error => {
                console.error('登出失败:', error)
                reject(error)
            })
        })
    },
      async getUserInfo({ commit }) {
          try {
              // 获取用户信息
              const userInfo = await request.get('/api/user/info')
              commit('SET_USER_INFO', userInfo)
              
              // 获取菜单数据
              const { data } = await request.get('/api/user/menus')
              // 同时设置菜单树和权限
              commit('SET_MENU_TREE', data)
              commit('SET_PERMISSIONS', data)
              commit('SET_MENUS', data)
              
              return userInfo
          } catch (error) {
              return null
          }
      },
      async register(_, userInfo) {
          const response = await register(userInfo)
          return response.data
      }
  },
    getters: {
        hasPermission: (state) => (permission) => {
            return state.permissions.has(permission)
    }
  }
}) 

