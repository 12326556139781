import { debounce } from 'lodash'

export default {
  data() {
    return {
      tableHeight: null,
      resizeObserver: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      const tableEl = this.$el.querySelector('.el-table')
      if (tableEl) {
        this.resizeObserver = new ResizeObserver(debounce(() => {
          this.updateTableHeight()
        }, 100))
        this.resizeObserver.observe(tableEl)
      }
    })
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  },
  methods: {
    updateTableHeight() {
      const tableEl = this.$el.querySelector('.el-table')
      if (!tableEl) return

      try {
        const windowHeight = window.innerHeight
        const rect = tableEl.getBoundingClientRect()
        if (!rect) return

        const offsetTop = rect.top
        const footerHeight = 60 // 分页器高度
        this.tableHeight = windowHeight - offsetTop - footerHeight - 20 // 20是底部边距
      } catch (error) {
        console.warn('计算表格高度时出错:', error)
      }
    }
  }
} 