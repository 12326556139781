import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import {setupGlobalError} from '@/utils/globalError'
import tableResizeMixin from './mixins/tableResizeMixin'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import moment from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')
Vue.use(Antd)
Vue.use(ElementUI)

Vue.config.productionTip = false

// 注册全局错误处理
setupGlobalError()

// 注册全局 mixin
Vue.mixin(tableResizeMixin)

// 忽略 ResizeObserver 警告
const resizeHandler = () => {
  const resizeObserverError = 'ResizeObserver loop completed with undelivered notifications.'
  window.addEventListener('error', e => {
    if (e.message === resizeObserverError) {
      e.stopImmediatePropagation()
    }
  })
}
resizeHandler()

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
