import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}

export function register(data) {
  return request({
    url: '/auth/register',
    method: 'post',
      data: {
          username: data.username,
          password: data.password,
          realName: data.realName
      }
  })
}

// 添加登出方法
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  })
}

// 获取用户菜单树
export function getUserMenuTree() {
  return request({
    url: '/menu/userMenuTree',
    method: 'get'
  })
}