import axios from 'axios'
import router from '@/router'
import {Message} from 'element-ui'
import {BUSINESS_CODE_MAP} from './errorCode'

// 创建axios实例
const service = axios.create({
    baseURL: '/api',
    timeout: 5000,
    withCredentials: true  // 允许跨域请求携带认证信息
})

// 用于防止重复跳转登录页
let isRedirecting = false

// 请求拦截器
service.interceptors.request.use(
  config => {
      // 不再需要手动添加 token，cookie 会自动带上
    return config
  },
  error => {
    Message.error('请求配置错误')
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
      const res = response.data

      // 处理成功响应
      if (res.code === 200) {
          // 对于登录接口和菜单树接口，需要返回完整的响应数据
          if (response.config.url === '/auth/login' || response.config.url === '/menu/userMenuTree') {
              return res
          }

          // 对于其他接口，如果响应中包含data字段，返回data
          if (res.data !== undefined) {
              return res.data
          }
          // 否则返回整个响应对象
          return res
      }

      // 处理 token 过期或未登录
      if (res.code === 401) {
          if (!isRedirecting) {
              isRedirecting = true
              router.push('/login').finally(() => {
                  // 跳转完成后重置标记
                  isRedirecting = false
              })
          }
          return
      }

      // 权限不足
      if (res.code === 403) {
          Message.error(res.msg || '没有权限访问该资源')
          return Promise.reject(new Error(res.msg || '没有权限访问该资源'))
      }

      // 处理业务错误
      if (BUSINESS_CODE_MAP[res.code]) {
          Message.error(BUSINESS_CODE_MAP[res.code])
          return Promise.reject(new Error(BUSINESS_CODE_MAP[res.code]))
      }

      // 其他错误
      Message.error(res.msg || '未知错误')
      return Promise.reject(new Error(res.msg || '未知错误'))
  },
  error => {
      console.error('请求错误:', error)
      return Promise.reject(new Error('操作失败，请稍后重试'))
  }
)

export default service