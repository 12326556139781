import Vue from 'vue'
import {logError} from './errorLog'

export function setupGlobalError() {
    // Vue 错误处理
    Vue.config.errorHandler = (error, vm, info) => {
        // 记录 Vue 组件错误
        logError({
            type: 'vue',
            level: 'error',
            error: error.message,
            stack: error.stack,
            info,
            component: vm.$options.name || 'anonymous',
            props: vm.$props,
            data: vm.$data,
            url: window.location.href,
            timestamp: new Date().getTime()
        })

        // 开发环境下打印错误
        if (process.env.NODE_ENV === 'development') {
            console.group('Vue Error Info')
            console.error('Error:', error)
            console.error('Component:', vm.$options.name)
            console.error('Info:', info)
            console.groupEnd()
        }
    }

    // JS 运行时错误和资源加载错误
    window.onerror = function (message, source, lineno, colno, error) {
        logError({
            type: 'js',
            level: 'error',
            message,
            source,
            lineno,
            colno,
            stack: error?.stack,
            url: window.location.href,
            timestamp: new Date().getTime()
        })

        if (process.env.NODE_ENV === 'development') {
            console.group('JavaScript Runtime Error')
            console.error('Message:', message)
            console.error('Source:', source)
            console.error('Line:', lineno)
            console.error('Column:', colno)
            console.error('Error:', error)
            console.groupEnd()
        }

        return false // 返回 false 让错误继续向上抛出
    }

    // Promise 未捕获错误
    window.addEventListener('unhandledrejection', event => {
        logError({
            type: 'promise',
            level: 'error',
            message: event.reason?.message || 'Promise rejection',
            stack: event.reason?.stack,
            url: window.location.href,
            timestamp: new Date().getTime()
        })

        if (process.env.NODE_ENV === 'development') {
            console.group('Unhandled Promise Rejection')
            console.error('Reason:', event.reason)
            console.groupEnd()
        }

        event.preventDefault()
    })

    // 资源加载错误
    window.addEventListener('error', event => {
        // 过滤 JS 运行时错误，只处理资源加载错误
        if (event.target && (event.target.tagName === 'SCRIPT' ||
            event.target.tagName === 'LINK' ||
            event.target.tagName === 'IMG')) {
            logError({
                type: 'resource',
                level: 'error',
                message: `Failed to load ${event.target.tagName.toLowerCase()}: ${event.target.src || event.target.href}`,
                source: event.target.src || event.target.href,
                url: window.location.href,
                timestamp: new Date().getTime()
            })
        }
    }, true) // 使用捕获阶段
} 