// HTTP 错误码映射
export const ERROR_CODE_MAP = {
    400: '请求参数错误',
    401: '未登录或登录已过期',
    403: '没有权限访问该资源',
    404: '请求的资源不存在',
    405: '请求方法不允许',
    408: '请求超时',
    429: '请求过于频繁，请稍后再试',
    500: '服务器内部错误',
    502: '网关错误',
    503: '服务暂时不可用',
    504: '网关超时'
}

// 业务错误码映射
export const BUSINESS_CODE_MAP = {
    // 用户相关 (1xxxx)
    10001: '用户名或密码错误',
    10002: '账号已被锁定',
    10003: '验证码错误',
    10004: '用户不存在',
    10005: '用户已存在',
    10006: '密码强度不够',
    10007: '旧密码错误',
    10008: '账号在其他设备登录',
    10009: '用户信息获取失败',

    // 权限相关 (2xxxx)
    20001: '无访问权限',
    20002: '角色不存在',
    20003: '权限分配失败',
    20004: '当前用户无此操作权限',

    // 数据操作相关 (3xxxx)
    30001: '数据添加失败',
    30002: '数据更新失败',
    30003: '数据删除失败',
    30004: '数据不存在',
    30005: '数据已存在',
    30006: '数据格式错误',

    // 文件操作相关 (4xxxx)
    40001: '文件上传失败',
    40002: '文件大小超出限制',
    40003: '文件类型不支持',
    40004: '文件下载失败',
    40005: '文件不存在',

    // 业务操作相关 (5xxxx)
    50001: '烟站信息添加失败',
    50002: '乡镇信息添加失败',
    50003: '考烟辅导员添加失败',
    50004: '温度数据获取失败',
    50005: '湿度数据获取失败',
    50006: '监测点不存在',

    // 第三方服务相关 (6xxxx)
    60001: '短信发送失败',
    60002: '邮件发送失败',
    60003: '第三方服务调用失败',

    // 系统相关 (9xxxx)
    90001: '系统维护中',
    90002: '系统升级中',
    90003: '系统繁忙，请稍后再试',
    90004: '操作过于频繁',
    90005: '会话已过期'
}

// 错误类型
export const ERROR_TYPES = {
    NETWORK: 'network',    // 网络错误
    TIMEOUT: 'timeout',    // 超时错误
    BUSINESS: 'business',  // 业务错误
    AUTH: 'auth',         // 认证错误
    PARAMS: 'params',     // 参数错误
    SERVER: 'server'      // 服务器错误
}

// 错误级别
export const ERROR_LEVELS = {
    INFO: 'info',         // 信息
    WARNING: 'warning',   // 警告
    ERROR: 'error',       // 错误
    CRITICAL: 'critical'  // 严重错误
}

// 获取错误信息的辅助函数
export function getErrorMessage(code, defaultMessage = '未知错误') {
    return BUSINESS_CODE_MAP[code] || ERROR_CODE_MAP[code] || defaultMessage
}

// 获取错误类型的辅助函数
export function getErrorType(code) {
    if (code >= 10000) return ERROR_TYPES.BUSINESS
    switch (Math.floor(code / 100)) {
        case 4:
            return code === 401 ? ERROR_TYPES.AUTH : ERROR_TYPES.PARAMS
        case 5:
            return ERROR_TYPES.SERVER
        default:
            return ERROR_TYPES.NETWORK
    }
}

// 获取错误级别的辅助函数
export function getErrorLevel(code) {
    if (code === 401 || code === 403) return ERROR_LEVELS.WARNING
    if (code >= 500) return ERROR_LEVELS.ERROR
    if (code >= 400) return ERROR_LEVELS.WARNING
    return ERROR_LEVELS.INFO
} 